// Home features - Card layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );

  // Home features - Overall card style
  .homeFeature:not(.homeFeatureSnippets) {
    @include card (
      $card-image-border-radius: 2px,
      $card-box-shadow: 0,
      $card-hover-box-shadow: 0,
      $card-details-padding: $spacer * 0.75,
      $card-details-background-colour: transparent,
      $card-hover-details-background-colour: transparent
    );
    @include card-basic;

    h2 {
      background-color: $_accent-colour;
      border-radius: $border-radius;
    }
  }
}


// Home feeds - Overall layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Home feeds - Card layout
.homeFeed .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 3
  );
  // Home feeds - Overall card style
  .feedItem {
    @include card;
    @include card-basic;
    h3 {
      background-color: $brand-primary;
      border-radius: $border-radius;
    }
  }
}



// Listed posts - Overall card style
.listedPost:not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
  h2 {
    background-color: $brand-primary;
    border-radius: $border-radius;
  }
}