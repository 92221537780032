// Hide home feeds
.homeFeedsWrapper {
  display: none;
}


// Website skeleton - Layout
html {
  background-image: url($assets-path + 'access-background.png');
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  position: relative;
  padding: $spacer * 3;
  overflow: hidden;
  @include max-width(xl) {
    padding: $spacer * 2;
  }
  @include max-width(lg) {
    padding: $spacer;
  }
}

body {
  border-radius: $border-radius;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: rgba($black, 0.2) 0px 8px 24px;
  max-height: calc(100vh - #{$spacer * 6});
  @include max-width(xl) {
    max-height: calc(100vh - #{$spacer * 4});
  }
  @include max-width(lg) {
    max-height: calc(100vh - #{$spacer * 2});
  }
}


// Tagline - Layout
.pageHeader .headerContent .tagline {
  padding-left: $spacer;
  margin-left: $spacer * 0.5;
  border-left: 1px solid $border-colour;
}

// Tagline - Format
.pageHeader .headerContent .tagline img {
  vertical-align: middle;
  max-height: 40px;
  @media (max-width: $logo-breakpoint) {
    max-height: 30px;
  }
}


// Donate button menu
.menuMain .topLevel li.level1 > a[aria-label*="fundraiser"] {
  @include button (
    $btn-background-colour: $donate-colour,
    $btn-text-colour: text-contrast($donate-colour),
    $btn-padding-x: 1em,
    $btn-padding-y: 0.5em
  );
  @media (min-width: #{$nav-breakpoint  + 1px}) {
    margin-left: #{$spacer * 0.25};
  }
  @media (max-width: $nav-breakpoint)  {
    margin: 5px 20px 10px;
    background-color: darken($donate-colour, 10%);
  }
}


// Fundraise button menu
.menuMain .topLevel li.level1 > a[aria-label*="fundraising"] {
  @include button (
    $btn-background-colour: $brand-secondary,
    $btn-text-colour: text-contrast($brand-secondary),
    $btn-padding-x: 1em,
    $btn-padding-y: 0.5em
  );
  @media (max-width: $nav-breakpoint)  {
    margin: 5px 20px;
  }
}


@media (min-width: #{$nav-breakpoint + 1px}) {
  .menuMain .topLevel li.level1:nth-of-type(1) > a,
  .menuMain .topLevel li.level1:nth-of-type(2) > a {
    border-right: 1px solid $border-colour;
  } 
}


// Burger button - Format
.menuMainAlt:hover, .menuMainAlt.active {
  background-color: $burger-btn-background-colour;
  color: $brand-primary;
}


// Burger button - Mobile format
@media (max-width: $burger-btn-bar-breakpoint)  {
  .menuMainAlt {
    background-color: $brand-primary;
    color: text-contrast($brand-primary);
    &:hover, &.active {
      background-color: darken($brand-primary, 10%);
      color: text-contrast(darken($brand-primary, 10%));
    }
  }
}


// Carousel read more - Layout
.carouselSlideDetail.appeal .carouselSlideReadMore,
.carouselSlideDetail.appeal .readMore {
  display: table;
}


// Carousel details - Layout
@media (min-width: #{$carousel-breakpoint + 1px}) {
  .carouselDetailWrapper {
    width: calc(100% - #{$site-bleed * 2});
    margin: 0 auto;
  }
}


// Carousel details - Mobile layout
@media (max-width: $carousel-breakpoint) {
  .carouselSlideDetail {
    width: calc(100% - #{$card-gap-width});
    margin: -2rem auto 0;
    z-index: 2;
    border-radius: $border-radius;
  }
}


// Carousel appeals buttons - Format
.carousel #totaliserActions { 
  display: none;
}


.homepage  .carouselSlideDetail {
  margin-bottom: calc(60px + #{$card-gap-width / 2});
}


// Homepage layout
.homepage .contentContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .carousel { order: -2; }
  .homeFeaturesWrapper { order: -1; }

  @media (min-width: #{$carousel-breakpoint + 1px}) {
    //.homeFeaturesWrapper .homeFeatures { margin-top: -60px; }
  }
}


// Home Features - Card layout
.homeFeatures .homeFeature:not(.homeFeatureSnippets),
.homeFeed .feedList .feedItem, 
.listedPost {

  [class*="image-link"] {
    border-radius: $border-radius;
  }

  [class*="DetailsWrapper"]:not(.publishDetailsWrapper),
  [class^="listed"][class$="Text"] {
    margin-top: $spacer * -3;
    z-index: 1;
    padding: #{$spacer * 1.5};

    @media (max-width: map-get($breakpoints, md)) {
      margin-top: $spacer * -1.75;
      padding: #{$spacer * 0.75};
    }

    h2 {
      border-radius: $border-radius;
      padding: $spacer * 0.75 $spacer * 0.5;
      box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.3);
    }
    p {
      padding: $spacer* 0.5;
    }

  }
}


//  Homefeature - Home intro - Layout
.homeFeatures .homeFeature.homeFeatureSnippets {
  position: relative;
  z-index: 1;
  overflow: visible;
  padding: $home-intro-padding-y #{$card-gap-width / 2};
  margin: #{$spacer * 2} 0 0;

  // Background
  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $home-intro-background-colour;
    z-index: -1;
  }
  a[class*='button'] {
    @include button (
      $btn-background-colour: $_accent-colour,
      $btn-text-colour: $white
    );
  }
}


// Homepage stats - Stat format
.homefeaturecategory-homeboximpactstats td:first-child > * {
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: $white;
  color: transparent;
  @media (max-width: map-get($breakpoints, md)) {
    -webkit-text-stroke-width: 1.5px;
  }
}


//  Carousel totaliser - Format
.carousel .totaliserPanel {
  display: block;
  width: 100%;
  position: relative;
  margin-top: $spacer;
  .totaliserBG {
    width: 35px;
    background-color: $white;
    .totaliserDone {
      background-color: $_accent-colour;
    }
  }
  .targetWrapper, .raisedWrapper {
    padding-top: 5px;
    margin-top: 0;
  }
  .targetWrapper {
    transform: translateY(100%);
    margin-left:auto;
  }

  .raisedWrapper .totaliserFigure {
    color: $_accent-colour;
  }
}


// Home Features -  Overall layout
.homeFeatures .homeFeature {
  flex: 1;
  min-width: $card-min-width;
  &.homeFeatureSnippets {
    flex: unset;
    width: 100%;
  }
}


// Footer -  Layout
.pageFooter {
  max-width: $container-max-width;
  margin: 0 auto;
  display: flex;
  .footerBox {
    width: 100%;
    max-width: 831.22px;
    padding: 0 $site-bleed;
    margin: 0 auto;
    ul.footerLinks {
      margin-bottom: #{$spacer * 4};
    }
  }

  @media (min-width: map-get($breakpoints, md) + 1px) {
    .footerBox {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      > * {
        flex: 1;
        margin: 0;
      }
      p {
        text-align: left;
      }
      img {
        max-height: 50px;
        object-fit: contain;
        object-position: right bottom;
      }
    }
    ul.footerLinks {
      flex: unset;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      li  {
        margin: 0 5px;
        &:not(:last-of-type):after {
          content: '|';
          display: inline-flex;
          padding-left: 5px;
        }
      }
    }
  }

}


// Homepage stats - Summary format
.homefeaturecategory-homeboximpactstats td:last-child {
  max-width: 450px;
  margin-right: auto;
}


// Homepage quick giving panel - Layout
.homepage-quickgiving--container {
  max-width: $container-max-width;
  flex-direction: column;
  // align-items: flex-start;
  .homepage-quickgiving--copy {
    margin-right: 0;
    margin-bottom: #{$spacer * 1.5};
  }
}


// Quick Giving Panel - Donation selected
.quickGivingPanel .donationAmount.donationSelected {
  box-shadow: inset 0px 0px 0px 2px $donate-colour;
}


// Homepage quick giving panel - Background
.homefeaturecategory-homeboxquickgiving { z-index: 1; }
.homefeaturecategory-homeboxquickgiving:before { z-index: -1; }


// Homepage quick giving panel - Layout
.homepage-quickgiving--container  {
  width: calc(100% - $spacer);
  padding: $spacer * 3;
  max-width: max-content;
  border-radius: $border-radius;
  background-color: $grey-lightest;
  z-index: 1;
  @media (max-width: map-get($breakpoints, md)) {
    padding: #{$spacer * 2} $site-bleed;
  }
}